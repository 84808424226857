.singularBlogPostContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 80%;
    margin-top: 5%;
}

.blogTitle {
    text-align: left;
}

.dateAuthor {
    text-align: left;
    color: rgb(135, 135, 135);
    font-style: italic;
}

.content {
    text-align: left;
    line-height: 1.5;
}

.blogImage {
    height: 80vh;
    object-fit: cover;
    object-position: 40px 0 0 0;

    margin: 5%;
}