.wallpaper {
    position: fixed; 
    top: 60px;
    left: 0;
    width: 100vw;  
    height: 100vh;  
    object-fit: cover; 
    z-index: -1;  
}

.signUpContainer {
    position: relative;  
    top: -60px;
    height: fit-content;
    width: fit-content;
    z-index: 996;
    background-color: white;
    display: block;
    margin: auto;
    padding: 4%;
    background-color: #f9f9f9;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1),
                -10px -10px 10px white;
}

.parentdiv {
    width: 100%;
    height: 100vh;  
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputElementsParent {
    display: flex;
    justify-content: center;
}

.inputElements {
    text-align: center;
}

.signupButtons {
    margin-top: 10px;
}

.variant1 {
    margin-right: 25px;
}
