/* TransitionText.css */
.transition-text {
    display: inline-block;
    overflow: hidden;

    width: 80%;
  }
  
  .letter {
    display: inline-block;
    transform: translateY(-20px);
    opacity: 0;
  }
  
  @keyframes fade-in {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  