.postContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    padding: 20px;
    box-sizing: border-box;
}

.blogPostsTitle {
    margin-top: 5%;
}  

@media screen and (max-width: 1100px) {
    .postContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 20px; 
        padding: 20px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 700px) {
    .postContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr); 
        gap: 20px; 
        padding: 20px;
        box-sizing: border-box;
    }
}