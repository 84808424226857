.feedbackContainer {
    width: 80%;
}

.feedback {
    margin: none;
    padding: 3%;
    border-radius: 5%;

    scale: 0.8;
    
    box-shadow: 20px 20px 40px 5px rgb(214, 255, 216);
}

.feedback .name {
    font-weight: bold;
}

.feedback .ref {
    font-style: italic;
    color: rgb(79, 182, 79);
}

.feedback .longcontent {
    font-size:20px;
}

/* 
feedbackCarousel
    feedback
        name
        ref
        content
*/