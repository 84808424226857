/* Define the keyframes for the slide-in animation */
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Initial style for the infographic div */
  .infographic {
    position: relative;
    width: 100%;
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }
  
  /* Final style when the animation is applied */
  .infographic.slide-in {
    animation: slideInFromLeft 0.5s forwards;
  }
  
  /* Additional styles for content inside the infographic */
  .infographic img {
    display: block;
    margin: 10px auto;
    max-width: 100%;
  }
  