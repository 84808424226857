.timelineImage {
    width: 70%;
    height: 70%;
    border-radius: 10px;
}

@media screen and (max-width: 780px) {
    .timelineImage {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}