.aboutBody {
    width: 80%;
    margin: auto;
}

.aboutContainer {
    display: flex;
    grid-template-columns: 40% 60%;
    column-gap: 5%;

    padding: 5%;
    width: 100%;
}

.blurb {
    text-align: left;
    flex: 1;
}

.blurb .signature {
    font-family: "Dancing Script", cursive;
    font-weight: bold;
    color: black;
}

.imgContainer {
    margin: none;
    padding: none;

    flex: 1;
}

.imgContainer img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    object-position: 0 0 0 0;
}

/* profile -> person -> bioImageContainer */
.profiles {
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-content: center;
    justify-items: center;
    gap: 15px;
    width: 90%;
}

.person {
    width: 250px;
    height: 400px;
    display: grid;
    grid-template-rows: repeat(4, auto);
    border-radius: 1.5%;
    margin-bottom: 5%;
    padding:4%;

    background-color: #f9f9f9;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1),
    -10px -10px 10px white;
}

.person img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    padding: 10%;
    border-radius: 50%;
}

.title {
    font-style: italic;
    font-size: 1.2rem;
}

.school {
    font-size: 1.2rem;
}

@media screen and (max-width: 1000px) {
    .profiles {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-content: center;
        justify-items: center;
        margin-top: 5%;
    }
}

@media screen and (max-width: 800px) {
    .aboutContainer {
        display: flex;
        flex-direction: column;
    
        padding: 5%;
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .profiles {
        display: grid;
        grid-template-columns: repeat(1, auto);
        align-content: center;
        justify-items: center;
        margin-top: 5%;
    }
}