.question{
    padding-top:3%;
    padding:auto;
    margin:auto;
    width: 80%;
}
.header {
    width: 80%;
    margin: auto;
    margin-top: 5%;
    padding: none;

    justify-content: center;
    align-items: center;
    
}