.header {
    width: 75%;
    margin: auto;
    margin-top: 5%;
    padding: none;

    justify-content: center;
    align-items: center;
}

.heady {
    text-align: left;
}
.section {
    width: 100%;
}
.processinfo{
    margin: auto;
    padding: none;
    max-width: 80%;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

.infographic {
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

/* Final style when the animation is applied */
.infographic.slide-in {
  animation: slideInFromLeft 0.5s forwards;
}