
.footer {
    padding: 3%;
    margin-top: 5%;
    height: 10%;
    background-color: rgb(240, 239, 239);
    bottom: 9%;
    width: 100%;
    
    z-index: 998;
}

/* .footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #efefef;
    text-align: center;
  } */
.customLink {
    text-decoration: none; /* Removes the underline */
    color: inherit;
}

.footerTitle {
    font-weight: bold;
    color: #2f601b;
}