.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px; 
}

.PDFContainer {
    width: 100%;
    max-width: 900px; 
    text-align: center;
}

.PDF {
    width: 100%;
    max-width: 900px;
    height: 900px; 
    border: 1px solid #ccc;
}

.VolunteerResourcesTOC {
    display: flex;
    flex-direction: row;
    gap: 10px;

    margin: none;
    padding: none;
    margin-top: -4%;
    margin-bottom: 4%;
}

@media (max-width: 768px) {
    .PDF {
        width: 90%; 
        max-width: 100%; 
        height: 600px; 
    }
}