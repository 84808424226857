.loginButtons {
    margin-top: 8%;
    padding: none;
    width: fit-content;

    display: flex;
    flex-direction: row;
}

.loginTitle {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;

    padding: 5px;
    margin: 5px;
}

.signupTitle {
    border: none;
    border-radius: 5px;
    color: white;

    padding: 6px;
    margin: 5px;
    background:linear-gradient(to right, #5cbc33, rgb(48, 159, 129));
}