.wallpaper {
    position: fixed;  
    top: 40px;
    left: 0;
    width: 100vw; 
    height: 100vh;  
    object-fit: cover;
    z-index: -1;  
}

.loginContainer {
    position: relative;
    top: -80px;
    height: fit-content;
    width: fit-content;
    z-index: 996;
    background-color: white;
    display: block;
    margin: auto;
    padding: 4%;
    background-color: #f9f9f9;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1),
                -10px -10px 10px white;
}

.parentdiv1 {
    width: 100%;
    height: 100vh; 
    display: flex;
    align-items: center;
    justify-content: center;
}
