.privacy-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    margin-top: 5%;
  }
  
  .privacy-policy h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .privacy-policy h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #333;
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
    color: #555;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    margin: 0;
    padding-left: 20px;
  }
  
  .privacy-policy li {
    margin-bottom: 10px;
  }
  
  .privacy-policy strong {
    font-weight: bold;
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  