.bodyContent {
    width: 80%;
    height: 70vh;
    margin:auto;
    padding: none;

    z-index: 999;
}

.pnf {
    scale: 80%;
}